/* All styles for screen size over 992px
================================================== */
@media only screen and (min-width: 992px) {
    nav#menu.main-menu {
      display: block !important;
    }
  
    #mm-menu.main-menu {
      display: block !important;
    }
  
    header .btn_mobile {
      display: none !important;
    }
  
    /* Menu */
    .main-menu {
      position: fixed;
      z-index: 9;
      width: auto;
      top: 12px;
      right: 15px;
      float: right;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      // color: #fff;
    }
    .main-menu ul,
    .main-menu ul li {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
    }
    .main-menu ul a,
    .main-menu ul li a {
      position: relative;
      margin: 0;
      padding: 0;
      display: block;
      padding: 5px 10px;
      white-space: nowrap;
    }
  
    /*First level styles */
    .main-menu > ul > li span > a {
      color: #fff;
      padding: 0 8px 15px 8px;
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: 500;
    }
    .main-menu > ul > li span:hover > a {
      opacity: 0.7;
    }
  
    header.sticky .main-menu > ul > li span > a {
      color: #444;
    }
    header.sticky .main-menu > ul > li span:hover > a {
      opacity: 1;
      color: #fc5b62;
    }
  
    /* Submenu*/
    .main-menu ul ul {
      position: absolute;
      z-index: 1;
      visibility: hidden;
      left: 3px;
      top: 100%;
      margin: 0;
      display: block;
      padding: 0;
      background: #fff;
      min-width: 200px;
      -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
      -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
      transform: scale(0.4);
      transform-origin: 10% top;
      transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
      opacity: 0;
      -moz-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
      transition: all 0.2s ease;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      border-radius: 3px;
    }
    .main-menu ul ul:before {
      bottom: 100%;
      left: 15%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #fff;
      border-width: 7px;
      margin-left: -7px;
    }
    .main-menu ul ul li {
      display: block;
      height: auto;
      padding: 0;
    }
    .main-menu ul ul li a {
      font-size: 13px;
      font-size: 0.8125rem;
      color: #555;
      border-bottom: 1px solid #ededed;
      display: block;
      padding: 15px 10px;
      line-height: 1;
    }
  
    .main-menu ul li:hover > ul {
      padding: 0;
      visibility: visible;
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s;
      -webkit-transition-duration: 0s, 0.2s, 0.2s;
      transition-duration: 0s, 0.2s, 0.2s;
    }
  
    .main-menu ul ul li:first-child a:hover {
      -webkit-border-radius: 3px 3px 0 0;
      -moz-border-radius: 3px 3px 0 0;
      -ms-border-radius: 3px 3px 0 0;
      border-radius: 3px 3px 0 0;
    }
    .main-menu ul ul li:last-child a {
      border-bottom: none;
    }
    .main-menu ul ul li:last-child a:hover {
      -webkit-border-radius: 0 0 3px 3px;
      -moz-border-radius: 0 0 3px 3px;
      -ms-border-radius: 0 0 3px 3px;
      border-radius: 0 0 3px 3px;
    }
    .main-menu ul ul li:hover > a {
      background-color: #f9f9f9;
      color: #fc5b62;
      padding-left: 18px;
    }
  }
  /* All styles for screen size under 991px
  ================================================== */
  @media only screen and (max-width: 991px) {
    nav#menu {
      display: none !important;
    }
  
    .mm-menu {
      background: #fff;
    }
  
    .main-menu {
      top: 0;
      right: 0;
      float: none;
    }
  
    ul.mm-listview {
      line-height: 25px;
    }
    ul.mm-listview li a {
      color: #555 !important;
      display: block;
    }
  }
  